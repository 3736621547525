<template>
    <v-dialog
        v-model="dialog"
        width="350"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-bars</v-icon>
                <span class="description-selected">
                    Imeis
                </span> 
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container v-if="imei_type == 'list'">
                    <v-row>
                        <v-col cols="12">
                            <template>
                                <v-card class="pt-0 pb-0">
                                    <v-list class="list-imeis-kardex">
                                        <v-list-item-group>
                                            <template v-for="(item, index) in imeis">
                                                <v-list-item :key="item">
                                                    <template>
                                                        <v-list-item-content class="pt-1 pb-1">
                                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                                        </v-list-item-content>                                            
                                                    </template>
                                                </v-list-item>
                                                <v-divider
                                                    v-if="index + 1 < imeis.length"
                                                    :key="index"
                                                ></v-divider>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container v-if="imei_type == 'rank'">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="imei_from"
                                label="Desde"
                                outlined
                                hide-details
                                readonly>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="imei_since"
                                label="Hasta"
                                outlined
                                hide-details
                                readonly>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-container class="pt-0 pb-0">
                    <v-row justify="center">                        
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleOk">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>                    
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ImeisDialog',
    data () {
        return {
            dialog: false,
            imei_type: null,
            imeis: null,
            imei_from: null,
            imei_since: null
        }
    },
    methods: {
        show (imei_data) {
            this.clearData();
            this.prepareData(imei_data);
            this.dialog = !this.dialog;

        },
        clearData () {
            this.imei_type = null;
            this.imeis = null;
            this.imei_from = null;
            this.imei_since = null;
        },
        prepareData (data) {if (data.includes('-')) {
                this.imei_type = 'rank';
                let imeis = imeis = data.split('-');
                this.imei_from = imeis[0];
                this.imei_since = imeis[1];
            } else {
                this.imei_type = 'list';
                this.imeis = data.split(',');
            }
        },
        handleOk () {
            this.clearData();
            this.dialog = false;
        }
    }
}
</script>

<style scoped>
.list-imeis-kardex{
    height: 35vh;
    overflow-y: auto;
}

.v-list-item{
    min-height: 30px !important;
}
</style>