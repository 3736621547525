<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8" class="pt-1">
                    <span class="headline font-weight-medium">Detalle de venta</span>
                </v-col>
                <v-col cols="4" class="pt-1">
                    <v-btn dark 
                        color="success" 
                        width="140"
                        class="float-right"
                        @click="handleDownloadReport">
                        Descargar XLS
                    </v-btn>
                    <v-btn v-if="viewButtonPrint()" 
                        dark 
                        color="blue" 
                        width="140" 
                        class="float-right mr-2"
                        @click="handlePrintVoucher">
                        Imprimir
                    </v-btn>                    
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Fecha</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">{{ sale.registration_date | moment("DD/MM/YYYY") }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span class="label-discount">Descuento</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase label-discount">{{ sale.discount | currency('S/ ') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Cliente</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span v-if="sale.customer" class="label-purchase">
                        {{ sale.customer.fullname }}
                    </span>
                    <span v-else class="label-purchase">
                        Público general
                    </span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Subtotal</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ sale.subtotal | currency('S/ ') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>{{ sale.type_document }}</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">
                        {{ sale.serie }}-{{ sale.correlative }}
                    </span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>IGV</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ sale.igv | currency('S/ ') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9" class="pt-0 pb-0"></v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Total</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ sale.total | currency('S/ ') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="detail"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            fixed-header
                        >
                            <template v-slot:item.quantity="{item}">
                                <v-icon v-if="item.imei" 
                                    class="mr-3" 
                                    color="blue" 
                                    size="18"
                                    @click="handleViewImeis(item)">
                                    fas fa-search-plus
                                </v-icon>
                                <span>{{ item.quantity }}</span>
                            </template>

                            <template v-slot:item.price="{item}">
                                <span>{{ item.price | currency('S/') }}</span>
                            </template>
                            <template v-slot:item.discount_value="{item}">
                                <span>{{ item.discount_value | currency('S/') }}</span>
                            </template>
                            <template v-slot:item.subtotal="{item}">
                                <v-icon v-if="item.is_bonus"
                                    size="18" 
                                    class="mr-2"
                                    color="green">
                                    fas fa-gift
                                </v-icon>
                                <span>{{ getAmountProductDiscount(item) | currency('S/') }}</span>                                
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <imeis-dialog ref="imeisDialog"></imeis-dialog>
    </v-container>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import router from '@/router/index';
import ImeisDialog from '../../../components/storehouses/ImeisDialog';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import TicketTools from '../../../helpers/TicketTools';

import JQuery from 'jquery';
let $ = JQuery;

export default {
    name: 'DetailSale',
    components: {
        ImeisDialog,
        OfficeLogin
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Ventas',
                    disabled: false,
                    href: '/app/reportes/reporte-ventas',
                }
            ],
            headers: [
                { text: 'CATEGORIA', value: 'category_name', sortable: false },
                { text: 'DESCRIPCION', value: 'description', sortable: false },             
                { text: 'CANTIDAD', value: 'quantity', sortable: false, align: 'end' },
                { text: 'PRECIO UND.', value: 'price', sortable: false, align: 'end' },
                { text: 'DESCUENTO', value: 'discount_value', sortable: false, align: 'end' },
                { text: 'TOTAL', value: 'subtotal', sortable: false, align: 'end' }
            ]
        }
    },
    computed: {
        ...mapState('report-sales', [
            'sale',
            'detail',
            'file_sales',
            'voucher'
        ])
    },
    methods: {
        ...mapActions('report-sales', [
            'getSaleDetail',
            'saleDetailExcel',
            'getSaleVoucher'
        ]),
        handleViewImeis (detail) {
            this.$refs.imeisDialog.show(detail.imei);
        },
        async handleDownloadReport () {
            await this.saleDetailExcel(this.sale);
            if (this.file_sales) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_sales;
                window.open(url);
            }
        },
        async handlePrintVoucher () {
            await this.getSaleVoucher(this.sale.id);
            if (this.voucher) {
                let content = await TicketTools.printTicketVenta(this.voucher);
                this.printVoucher(content);
            }
            
        },
        printVoucher (content) {
            $('<iframe>', {name: 'voucheriframe',class: 'printFrame'})
            .appendTo('body')
            .contents().find('body')
            .append(content);

            window.frames['voucheriframe'].focus();

            setTimeout(() => {                 
                window.frames['voucheriframe'].print();
             }, 400);

            setTimeout(() => { $(".printFrame").remove(); }, 1000);
        },
        viewButtonPrint() {
            let view = false;
            if (this.sale.type_document == 'TICKET' || 
                this.sale.type_document == 'BOLETA' ||
                this.sale.type_document == 'FACTURA') {
                    view = true;
                }

            return view;
        },
        getAmountProductDiscount(item) {
            let amount = (item.price * item.quantity) - item.discount_value;
            amount = Math.round( (amount) * 100) / 100;

            return amount;
        }
    },
    created () {
        this.getSaleDetail(this.sale);
    }
}
</script>

<style scoped>
.column-amount{
    text-align: right;
}

.label-purchase{
    font-weight: 600;
}

.label-discount{
    color: #F44336;
}
</style>